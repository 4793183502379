export const createOneLinkUrl = '/api/onelink/create';
export const createQrCodeUrl = '/api/qrcode';
export const getAllMediumBySourceIdUrl = '/api/source_medium/get_all/';
export const getAllOnelinkByUserIdUrl="/api/onelink/get_all/";
export const getOnelinkById="/api/onelink/get/";
export const updateOnelinkUrl="/api/onelink/update";
export const deleteOnelinkUrl="/api/onelink/delete";
export const getAllSourceUrl = '/api/source/get_all';
export const deleteSourceUrl="/api/source/delete";
export const updateSourceUrl="/api/source/update";
export const createSourceUrl="/api/source/create";
export const getAllMediumUrl = '/api/medium/get_all';
export const deleteMediumUrl="/api/medium/delete";
export const updateMediumUrl="/api/medium/update";
export const createMediumUrl="/api/medium/create";
export const createSourceMediumMappingUrl="/api/source_medium/create";
export const getAllSourceMediumUrl = '/api/source_medium/get_all';
export const getAllSourceWithMediumUrl = '/api/source_medium/get_all_source_with_medium';
export const deleteSourceMediumUrl="/api/source_medium/delete";
export const updateSourceMediumUrl="/api/source_medium/update";
export const createSourceMediumUrl="/api/source_medium/create";